import { createStore } from 'vuex'
export default createStore({
  state: {
    firstTimeVisitor: localStorage.getItem('firstTimeVisitor') || 'true',
    baseUrl: 'https://backend.adventjusosg.ch/wp-json/wp/v2',
    baseUrlForm: 'https://backend.adventjusosg.ch/wp-json/',
  },
  getters: {
    getVisitorStatus: state => {
      return state.firstTimeVisitor
    },
    getBaseUrl: state => {
      return state.baseUrl
    },
    getBaseUrlFrom: state => {
      return state.baseUrlForm
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
