import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import axios from "axios";
import router from './router'
import store from './store'
import { injections } from "./helpers/injections.js"

import '/src/css/main.scss';

const myApp = createApp(App)
myApp.config.globalProperties.$axios = axios;
myApp.config.globalProperties.$injection = injections;
myApp.use(store)
myApp.use(router)
myApp.mount('#app')
