<template>
  <article class="door" @click="openDoor" :class="doorIsOpen ? 'open' : '', doorCanBeOpened ? 'active' : 'inactive'">
    <p v-if="!doorIsOpen">no. {{content.acf.turdatum}}</p>
  </article>
  <DoorContent @closeDoor="closeDoor" :content="content" v-if="doorIsOpen" />
</template>

<script>
import DoorContent from "./DoorContent";
export default {
  name: "Door",
  components: {DoorContent},
  data() {
    return {
      doorIsOpen: false,
    }
  },
  emits: ['closedoor'],
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    date(){
      return new Date() // -> used in production
    },
    doorCanBeOpened(){
      let day = this.date.getDate()
      let month =this.date.getMonth() + 1
      if(month > 11){
        return parseInt(this.content.acf.turdatum) <= day;
      }else{
        return false
      }
    }
  },
  methods: {
    openDoor(){
      this.doorIsOpen = true
    },
    closeDoor(){
      this.doorIsOpen = false
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";
article.door{
  cursor: pointer;
  width: 100%;
  height: 200px;
  background: $co-akzent;
  color: $co-white;
  padding: $ga-half;
  transition: 0.5s;
  @include flex(column,flex-end,flex-end);
  @include tablet-down{
    height: 225px;
  }
  @include mobile-down{
    height: 200px;
  }
  p{
    user-select: none;
    @include fitalic(3rem,120%);
    @include tablet-down{
      @include fitalic(2rem,120%);
    }
    @include mobile-down{
      @include fitalic(1.8rem,120%);
    }
  }
  &.open{
    transform: scale(30);
    overflow-x: hidden;
    position: fixed;
  }
  &.inactive{
    pointer-events: none;
    background: #e8565630;
  }
}
</style>
