<template>
    <section v-if="newVisitor">
      <h1>Adventskalender 23</h1>
      <p>JUSO Kanton St.Gallen</p>
      <img src="@/assets/images/marx.png" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "IntroScreen",
  data() {
    return {
      newVisitor: true
    }
  },
  props: {},
  computed: {
    ...mapGetters({
      firstTimeVisitor: 'getVisitorStatus'
    })
  },
  methods: {
    setVisitorStatus(){
      if(this.firstTimeVisitor === 'true'){
        console.log('first time visitor')
        localStorage.setItem('firstTimeVisitor','false')
        this.newVisitor = true
      }else{
        this.newVisitor = false
      }
    },
    removeIntroscreen(){
      setInterval(() => {
        this.newVisitor = false;
      },5000);
    }
  },
  mounted() {
    this.setVisitorStatus();
    this.removeIntroscreen();
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";
section{
  background: $co-akzent;
  animation: fadeOut 5s ease-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  @include fixed(100vw,100vh,2){
    top: 0;
    left: 0;
  }
  @include flex(column,center,center);
  h1{
    color: $co-white;
    @include fbasic(10vw,120%);
  }
  p{
    color: $co-white;
    @include fitalic(3vw,120%)
  }
  img{
    max-width: 50%;
    max-height: 50%;
    position: absolute;
    bottom: -150px;
    @include tablet-down{
      bottom: -100px;
    }
    @include mobile-down{
      bottom: -40px;
    }
  }
  @keyframes fadeOut {
    0% {
      transform: scale(1) translateY(0);
    }
    70%{
      transform: scale(1.07) translateY(0);
    }
    100% {
      transform: scale(1.1) translateY(-120vh);
    }
  }
}
</style>
