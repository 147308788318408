<template>
<section v-if="overlayVisible">
  <img class="topleft" src="@/assets/images/bild1.png" alt="Glocke"/>
  <img class="topright" src="@/assets/images/bild4.png" alt="Kugel" />
  <img class="bottomleft" src="@/assets/images/bild2.png" alt="Baum" />
  <img class="bottomright" src="@/assets/images/bild3.png" alt="Kranz" />
</section>
</template>

<script>
export default {
  name: "Christmas",
  data() {
    return {
      overlayVisible: false
    }
  },
  props: {},
  computed: {},
  methods: {
    showChristmasOverlay(){
      setTimeout(() => {
        this.overlayVisible = true
      }, 10000)
    }
  },
  mounted() {
    this.showChristmasOverlay()
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";
section{
  pointer-events: none;
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: #e8565610;
  animation: zoomin 2s ease-out 1;
  img{
    width: auto;
    position: absolute;
    &.topleft{
      height: 180px;
      left: -60px;
      top: -60px;
      @include tablet-down{
       display: none;
      }
    }
    &.topright{
      height: 140px;
      right: -50px;
      top: -50px;
      @include tablet-down{
        display: none;
      }
    }
    &.bottomleft{
      height: 200px;
      left: -40px;
      bottom: -30px;
      @include tablet-down{
        height: 150px;
        left: -20px;
        bottom: -30px;
      }
      @include mobile-down{
        height: 120px;
      }
    }
    &.bottomright{
      height: 150px;
      right: -50px;
      bottom: -30px;
      @include tablet-down{
        height: 130px;
        right: -40px;
        bottom: -30px;
      }
      @include mobile-down{
        height: 110px;
      }
    }
  }
}
@keyframes zoomin {
  from{
    transform: scale(1.4);
  }
  to{
    transform: scale(1.0);
  }
}
</style>