<template>
  <div class="container" v-if="contentVisible">
    <p class="number">{{ content.acf.turdatum }}</p>
    <p id="close" @click="closeDoor">Close<br> Door</p>
    <h1>{{ content.title.rendered }}</h1>
    <div class="content" v-html="content.content.rendered">
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DoorContent",
  data() {
    return {
      contentVisible: false
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      baseUrlForm: 'getBaseUrlFrom'
    })
  },
  methods: {
    showContent() {
      setTimeout(() => {
        this.contentVisible = true
        this.initForm()
      }, 500);
    },
    initForm() {
      if (this.content.acf.hat_formular) {
        setTimeout(() => {
          let btn = document.createElement('button')
          btn.classList.add('submit')
          btn.innerText = 'Teilnehmen'
          this.$el.appendChild(btn)
          btn.addEventListener('click', () => {
            this.handleForm()
          })
        }, 500);
      }
    },
    handleForm() {
      console.log('handle form')
      let url = `${this.baseUrlForm}contact-form-7/v1/contact-forms/${this.content.acf.formular_id}/feedback`
      this.$axios.post(url)
          .then((res) => {
            console.log(res)
          })
    },
    closeDoor() {
      this.$emit('closeDoor')
    }
  },
  mounted() {
    this.showContent();
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";

div.container {
  position: absolute;
  top: $ga-main;
  left: $ga-main;
  width: calc(100% - calc(#{$ga-main} * 2));
  padding: 100px 20%;
  z-index: 3;
  @include tablet-down {
    padding: 100px 15%;
  }
  @include mobile-down {
    margin-top: -35px;
    padding: 0 0 130px 0;
    top: $ga-half;
    left: $ga-half;
    width: calc(100% - calc(#{$ga-half} * 2));
  }

  p#close {
    position: fixed;
    top: $ga-main;
    right: $ga-main;
    cursor: pointer;
    display: inline;
    color: $co-white;
    text-align: right;
    @include fbasic(2rem, 120%);
    @include mobile-down {
      top: $ga-half;
      right: $ga-half;
      @include fbasic(1.5rem, 120%);
      z-index: 20;
    }
  }

  h1 {
    color: $co-white;
    @include fitalic(4rem, 130%);
    @include tablet-down {
      @include fitalic(2.5rem, 120%);
    }
    @include mobile-down {
      @include fitalic(1.7rem, 120%);
    }
  }

  div.content {
    margin-top: $ga-half;
    color: $co-white;
    @include fbasic(1.5rem, 130%);
    @include tablet-down {
      @include fbasic(1.2rem, 130%);
    }
    @include mobile-down {
      margin-top: 0;
      overflow-x: hidden;
      @include fbasic(1.1rem, 130%);
    }
  }

  p.number {
    pointer-events: none;
    color: $co-white;
    position: fixed;
    bottom: -110px;
    right: -30px;
    @include fblack(20rem, 120%);
    @include tablet-down {
      @include fblack(18rem, 120%);
      bottom: -90px;
      opacity: .4;
    }
    @include mobile-down {
      @include fblack(13rem, 120%);
      position: relative;
      bottom: unset;
      right: unset;
    }
  }

  ::v-deep img{
    width: 100%;
    height: auto;
  }

}
</style>
