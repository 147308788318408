<template>
  <Christmas/>
  <IntroScreen/>
  <main v-if="doors">
    <section class="infobar top">
      <p>Adventskalender</p>
      <p>JUSO Kanton St.Gallen</p>
    </section>
    <section class="doors">
      <Door v-for="door in doors" :key="door.acf.turdatum" :content="door"/>
    </section>
    <section class="infobar bottom">
      <p>2023</p>
    </section>
  </main>
</template>

<script>
import Door from "../components/Door";
import IntroScreen from "../components/IntroScreen";
import {mapGetters} from 'vuex'
import Christmas from "../components/Christmas";

export default {
  name: 'Home',
  components: {Christmas, IntroScreen, Door},
  data() {
    return {
      doors: null
    }
  },
  computed: {
    ...mapGetters({
      baseUrl: 'getBaseUrl'
    })
  },
  methods: {
    getDoorContent() {
      let url = this.baseUrl + '/door?order=asc&per_page=24'
      this.$axios.get(url)
          .then((res) => {
            this.doors = res.data
          })
    }
  },
  mounted() {
    this.getDoorContent()
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/main.scss";

main {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: $ga-main;
  background: $co-gradient-1;
  background: radial-gradient(circle, $co-gradient-1 0%, $co-gradient-2 100%);
  @include mobile-down{
    padding: $ga-half;
  }
  section.infobar {
    @include flex(row, center, space-between);
    width: calc(100% - calc(#{$ga-main} * 2));
    position: absolute;
    z-index: 1;
    @include mobile-down{
      position: relative;
      width: 100%;
      @include flex(column, flex-start, space-between);
    }
    p {
      @include fbasic(2rem,120%);
      color: $co-akzent;
      @include mobile-down{
        @include fbasic(1.8rem,120%);
      }
    }
    &.top {
      top: $ga-main;
      left: $ga-main;
      margin-bottom: $ga-main;
      @include mobile-down{
        top: unset;
        left: unset;
        margin-bottom: $ga-half;
      }
    }
    &.bottom {
      bottom: $ga-main;
      left: $ga-main;
      margin-top: $ga-main;
      @include mobile-down{
        bottom: unset;
        left: unset;
        margin-top: $ga-half;
      }
    }
  }
  section.doors {
    @include grid(4);
    margin-bottom: calc(#{$ga-main} * 2);
    margin-top: calc(#{$ga-main} * 2);
    @include mobile-down{
      margin: 0 0;
    }
  }
}
</style>
